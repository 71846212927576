import  { type ReactNode, type HTMLProps , forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export const List = forwardRef<HTMLUListElement, { className?: string; children: ReactNode } & HTMLProps<HTMLUListElement>>(({ className, children, ...props }, ref) => {
	const baseClass = 'list-inside list-disc tracking-tight'
	return (
		<ul className={twMerge(baseClass, className)} ref={ref} {...props}>
			{children}
		</ul>
	)
})
