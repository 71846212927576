import  { type ReactNode, type HTMLProps , forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export const ListItem = forwardRef<HTMLLIElement, { className?: string; children: ReactNode } & HTMLProps<HTMLLIElement>>(({ className, children, ...props }, ref) => {
	const baseClass = 'xl:py-1 3xl:py-2 5xl:py-3 pl-6'
	return (
		<li className={twMerge(baseClass, className)} ref={ref} {...props}>
			{children}
		</li>
	)
})
